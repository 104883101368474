import React, { useState, useEffect, useContext } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import DocumentTitle from "../components/DocumentTitle"
import DropDown from "../components/DropDown"
import SubmitButton from "../components/SubmitButton"
import RevenueList from "../components/RevenueList"
import checkObjectValue from "../utils/checkObjectValue"
import FetchPeriodRevenueApi from "../apis/FetchPeriodRevenueApi"
import { periodReportLink, magazineReportLink } from "../utils/reportLink"
import AuthContext from "../contexts/Authentication"
import media from "../components/Media"
import dayjs from "dayjs"

const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto;

  ${media.desktop`
    width: 85%;
    padding-top: 8rem;
  `};
  ${media.mobile`
    width: 100%;
    padding: 8.5rem 1rem 0 1rem;
  `};
`

const Intro = styled.div`
  width: 100%;
  padding: 15px 14px;
  margin-bottom: 14px;
  background-color: #f6f6f8;
  border: 1px solid #e2e2e9;
  border-radius: 4px;
`

const Title = styled.h1`
  font-size: 1.5em;
  color: #000000;
  margin-bottom: 20px;
`

const Description = styled.p`
  font-size: 14px;
  line-height: 21px;
  color: #595757;
`

const CtrlBar = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  padding: 15px 14px;
  margin-bottom: 14px;
  margin-top: -10px;
`
const DropDownLabel = styled.div`
  padding-top: 0.4rem;
`

const DropDownItem = styled.div`
  padding-right: 1rem;
`

const DownloadButton = styled.button`
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
  border: none;
  cursor: ${({ disable }) => (disable ? "" : "pointer")};
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  font-weight: 400;
  font-size: 14px;
  margin-left: 0.5rem;

  ${media.mobile`
    margin-top: 10px;
  `};
`

function Revenue() {
  const { t } = useTranslation(["body", "documentTitle"])
  const [period, setPeriod] = useState({
    startYear: false,
    startMonth: false,
    endYear: false,
    endMonth: false,
  })
  const [step, setStep] = useState(0)
  const [step2Info, setStep2Info] = useState({
    version: null,
    time: null,
    platform: null,
  })
  const defaultFormat = {
    aycr: [],
    smarticle: [],
    total: 0,
    errorMessage: null,
  }
  const [periodRevenues, setPeriodRevenues] = useState(defaultFormat)
  const years = startYear => {
    const currentYear = new Date().getFullYear()
    let years = []
    startYear = startYear || 2022
    while (startYear <= currentYear) {
      years.push({ key: startYear, value: startYear })
      startYear++
    }
    return years
  }

  const months = Array.from({ length: 12 }, (value, key) => key + 1).map(
    value => {
      return { key: value, value: value }
    },
  )

  const setValue = (fnstring, value) => {
    const newAccess = { ...period }
    newAccess[fnstring] = value
    setPeriod(newAccess)
  }

  const { user } = useContext(AuthContext)
  const publisherId = user["publisherId"]
  const currency = user["currency"] || "NTD"
  const periodValues = checkObjectValue(period)

  useEffect(() => {
    if (periodValues) {
      if (
        period["startYear"] > period["endYear"] ||
        (period["startYear"] === period["endYear"] &&
          period["startMonth"] > period["endMonth"])
      ) {
        const newPeriod = { ...period }
        newPeriod["startYear"] = period["endYear"]
        newPeriod["endYear"] = period["startYear"]
        newPeriod["startMonth"] = period["endMonth"]
        newPeriod["endMonth"] = period["startMonth"]
        setPeriod(newPeriod)
      }
    }
  }, [periodValues, period])

  const logEvent = () => {
    const startDate = `${period["startYear"]}/${period["startMonth"]}/1`
    const endDate = `${period["endYear"]}/${period["endMonth"]}/1`
    const startMonth = dayjs(startDate).format("YYYYMM")
    const endMonth = dayjs(endDate).format("YYYYMM")
    const eventProperties = {
      "start month": startMonth,
      "end month": endMonth,
    }
    window.amplitude.getInstance().logEvent("queried revenue", eventProperties)
  }

  const submit = async () => {
    try {
      if (!periodValues) {
        throw Error("date is invalid")
      }

      const startDay = new Date(period["startYear"], period["startMonth"], 1)
      const endDay = new Date(period["endYear"], period["endMonth"], 1)
      const diffInYear = endDay.getFullYear() - startDay.getFullYear()
      const diffInMonth =
        diffInYear * 12 + (endDay.getMonth() - startDay.getMonth())
      if (diffInMonth > 60) {
        throw Error("date range is invalid")
      }

      setStep(1)
      const response = await FetchPeriodRevenueApi(publisherId, period)
      if (response.status === 200) {
        setPeriodRevenues({
          ...defaultFormat,
          aycr: response.data.revenues.aycr,
          smarticle: response.data.revenues.smarticle,
          total: response.data.total,
        })
        logEvent()
      } else {
        setPeriodRevenues({
          ...defaultFormat,
          errorMessage: response.message,
        })
      }
    } catch (error) {
      if (!period["startYear"] || !period["endYear"]) {
        alert(t("panel.message.chooseYear"))
      } else if (!period["startMonth"] || !period["endMonth"]) {
        alert(t("panel.message.chooseMonth"))
      } else {
        alert(t("panel.message.dateRangeLimited"))
      }
    }
  }

  const downloadReport = () => {
    const link =
      step < 2
        ? periodReportLink(publisherId, period)
        : magazineReportLink(publisherId, step2Info)
    const eventProperties = { url: link }
    window.amplitude
      .getInstance()
      .logEvent("downloaded report", eventProperties)
    window.open(link)
  }

  return (
    <React.Fragment>
      <DocumentTitle title={t("documentTitle:revenue")} />
      <Wrapper>
        <Intro>
          <Title>{t("manual.monthlyReport.title")}</Title>
          <Description>{t("manual.monthlyReport.description")}</Description>
        </Intro>
        <CtrlBar>
          <DropDownLabel>{t("panel.dropDown.startDate.label")}：</DropDownLabel>
          <DropDownItem>
            <DropDown
              data={years()}
              defaultValue={t("panel.dropDown.startDate.year")}
              selectedValue={period["startYear"]}
              tag="startYear"
              setValue={(f, v) => {
                setValue(f, v)
              }}
            />
          </DropDownItem>
          <DropDownItem>
            <DropDown
              data={months}
              defaultValue={t("panel.dropDown.startDate.month")}
              selectedValue={period["startMonth"]}
              tag="startMonth"
              setValue={(f, v) => {
                setValue(f, v)
              }}
            />
          </DropDownItem>
          <DropDownLabel>{t("panel.dropDown.endDate.label")}：</DropDownLabel>
          <DropDownItem>
            <DropDown
              data={years()}
              defaultValue={t("panel.dropDown.endDate.year")}
              selectedValue={period["endYear"]}
              tag="endYear"
              setValue={(f, v) => {
                setValue(f, v)
              }}
            />
          </DropDownItem>
          <DropDownItem>
            <DropDown
              data={months}
              defaultValue={t("panel.dropDown.endDate.month")}
              selectedValue={period["endMonth"]}
              tag="endMonth"
              setValue={(f, v) => {
                setValue(f, v)
              }}
            />
          </DropDownItem>
          <SubmitButton
            onClick={e => {
              e.preventDefault()
              submit()
            }}
          >
            {t("panel.button.countMonthlyRenenue")}
          </SubmitButton>
          {periodValues ? (
            <DownloadButton
              onClick={e => {
                e.preventDefault()
                downloadReport()
              }}
            >
              {t("panel.button.downloadExcel")}
            </DownloadButton>
          ) : null}
        </CtrlBar>
        <RevenueList
          step={step}
          publisherId={publisherId}
          currency={currency}
          period={period}
          periodRevenues={periodRevenues}
          setStep={setStep}
          setStep2Info={setStep2Info}
        />
      </Wrapper>
    </React.Fragment>
  )
}

export default Revenue
